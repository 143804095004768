import * as React from 'react';
import { LinkBarProps } from '../../../LinkBar.types';
import styles from '../../style/ClassicLinkBar.scss';
import LinkBar from '../../LinkBar';

const ClassicLinkBar: React.FC<Omit<LinkBarProps, 'styles'>> = props => {
  return <LinkBar {...props} styles={styles} />;
};

export default ClassicLinkBar;
